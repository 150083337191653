import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../src/css/styles.css";

// Add type declaration for Kakao Maps
declare global {
  interface Window {
    kakao: any;
  }
}

const LocationSection: React.FC = () => {
  const mapRef = useRef(null);
  const roadviewRef = useRef(null);
  const [mapLoadError, setMapLoadError] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_MAP_API_KEY}&autoload=false&libraries=services`;
    script.async = true;
    script.onerror = () => setMapLoadError(true);

    script.onload = () => {
      window.kakao.maps.load(() => {
        if (!mapRef.current || !roadviewRef.current) return;

        const mapCenter = new window.kakao.maps.LatLng(37.37166, 127.1121);
        const mapOption = {
          center: mapCenter,
          level: 3,
        };
        const map = new window.kakao.maps.Map(mapRef.current, mapOption);

        const overlayContent = `
          <div class="overlay_info">
            <a href="https://place.map.kakao.com/20494362" target="_blank">
              <strong>훈:어쿠스틱기타하우스</strong>
            </a>
            <div class="desc">
              <img src="/assets/img/locationImg.png" alt="로케이션 이미지">
              <span class="address">경기 성남시 분당구 백현로144번길 29-1 1층, 훈:어쿠스틱기타하우스음악교습소</span>
            </div>
          </div>
        `;

        const overlayPosition = new window.kakao.maps.LatLng(37.37166, 127.1121);
        const overlayPosition2 = new window.kakao.maps.LatLng(37.37145, 127.1171);
        const customOverlay = new window.kakao.maps.CustomOverlay({
          map,
          position: overlayPosition,
          content: overlayContent,
          yAnchor: 1,
        });

        customOverlay.setMap(map);

        (window.kakao.maps as any).event.addListener(customOverlay, "click", function () {
          alert("오버레이 클릭됨!");
        });

        const roadview = new window.kakao.maps.Roadview(roadviewRef.current);
        const rvClient = new window.kakao.maps.RoadviewClient();
        rvClient.getNearestPanoId(mapCenter, 50, function (panoId: string) {
          roadview.setPanoId(panoId, mapCenter);
        });

        (window.kakao.maps as any).event.addListener(roadview, "init", function () {
          const rvCustomOverlay = new window.kakao.maps.CustomOverlay({
            position: overlayPosition2,
            content: overlayContent,
            yAnchor: 1,
          });

          rvCustomOverlay.setMap(roadview);

          const projection = roadview.getProjection();
          const viewpoint = projection.viewpointFromCoords(rvCustomOverlay.getPosition(), rvCustomOverlay.getAltitude());

          roadview.setViewpoint(viewpoint);
        });
      });
    };

    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <section className="page-section bg-light" id="location">
      <Container>
        <Row className="text-center mb-4">
          <Col>
            <h2 className="section-heading text-uppercase">Location</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="mb-3">
            <div
              ref={mapRef}
              style={{
                width: "100%",
                height: "400px",
                border: "2px dashed #ccc",
                borderRadius: "10px",
                position: "relative",
                textAlign: "center",
                lineHeight: "400px",
                color: "#888",
              }}
            >
              {mapLoadError && <div>지도를 불러오지 못했습니다.</div>}
            </div>
            <div
              ref={roadviewRef}
              style={{
                width: "100%",
                height: "400px",
                borderRadius: "10px",
                display: mapLoadError ? "none" : "block",
              }}
            ></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LocationSection;
