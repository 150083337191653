import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PortfolioModal from "../modal/PortfolioModal";

const PortfolioSection: React.FC = () => {
  const portfolioItems = [
    {
      id: "1",
      title: "분당 기타 학원 추천 | Bruno Major ‘Nothing’ 연주법",
      subtitle: "감성적인 연주를 하고 싶다면 Bruno Major의 ‘Nothing’은 어떠세요?",
      image: "/assets/img/brunomajor.jpg",
      description: "초보자도 쉽게 배울 수 있는 Bruno Major ‘Nothing’ 기타 연주",
      url: "https://blog.naver.com/hun_guitar/223816869862",
    },
    {
      id: "2",
      title: "분당기타학원 | 기타 초보자에게 추천하는 쉬운 연습곡 Best 5",
      subtitle: "기타 초보자가 도전할 수 있는 쉽고 재밌는 곡 추천해주세요!",
      image: "/assets/img/guitarbest5.jpg",
      description: "기타를 처음 배우기 시작하면 손가락도 아프고 코드 전환도 어려워서 포기하고 싶어질 때가 많죠. 하지만 좋아하는 곡을 하나씩 완성하다 보면 점점 더 즐거워집니다! 오늘은 기타를 막 시작한 분들이 연습하기 좋은 인기 곡들을 소개해드릴게요.",
      url: "https://blog.naver.com/hun_guitar/223820062691",
    },
    {
      id: "3",
      title: "[분당기타학원] 기타 넥이 휘었을때, 기타 줄 떴을 때 대처방법!",
      subtitle: "기타 넥이 휘거나 줄이 많이 떠있을 때 어떻게 대처해야할까요?",
      image: "/assets/img/guitarneckbreak.jpeg",
      description: "​오늘은 넥이 휘거나 줄이 많이 떠있을 때 어떻게 대처해야하는지 알려드리려고 합니다! 천천히 따라오세요 ^^",
      url: "https://blog.naver.com/hun_guitar/223809644907",
    },
    {
      id: "4",
      title: "[분당/정자동 기타학원] 기타를 대여 해드립니다(회원한정)",
      subtitle: "기타를 바로 구입하기는 부담스러운데 대여가 가능한가요??",
      image: "/assets/img/guitar_RentalServices.jpeg",
      description: "네, 회원분들에 한하여 기타를 대여해드리고 있습니다 ^^",
      url: "https://m.blog.naver.com/PostView.naver?blogId=hun_guitar&logNo=223440487783&targetKeyword=&targetRecommendationCode=1&fromRecommendationType=KOREA_MAP&targetRecommendationDetailCode=1000",
    },
    {
      id: "5",
      title: "겨울방학특강! 내 아이를 정서적 부자로 만들어주세요",
      subtitle: "기타레슨이 아이의 정서발달에 도움이 될까요?",
      image: "/assets/img/emotionalWealth.jpg",
      description: "그럼요~ 당연히 도움이 되지요 :)",
      url: "https://m.blog.naver.com/PostView.naver?blogId=hun_guitar&logNo=223303556455&targetKeyword=&targetRecommendationCode=1&fromRecommendationType=KOREA_MAP&targetRecommendationDetailCode=1000",
    },
    {
      id: "6",
      title: "베이스기타 레슨!",
      subtitle: "어떤 팀에 들어가고 싶은데, 하나도 몰라요. 어떻게 하면 될까요?",
      image: "/assets/img/guitar_hunlogo.jpg",
      description: "훈어쿠스틱기타하우스에 오시면 누구든 도전할 수 있습니다!",
      url: "https://m.blog.naver.com/PostView.naver?blogId=hun_guitar&logNo=223030493475&referrerCode=0&searchKeyword=%EC%B0%AC%EC%96%91%ED%8C%80",
    },
    {
      id: "7",
      title: "[분당 기타학원] 은퇴 후 취미로 기타배우기! (Feat.90세 회원님)",
      subtitle: "은퇴 후 취미를 찾고 계신가요?",
      image: "/assets/img/guitar_afterRetirement.jpg",
      description: "90세 어르신도 기타를 배우셨답니다!",
      url: "https://blog.naver.com/hun_guitar/223451750868",
    },
    {
      id: "8",
      title: "기타연주시 조옮김(transpose)과 카포 사용법",
      subtitle: "기타치면서 노래를 하고 싶은데 키가 저랑 안맞아요 ㅠㅠ",
      image: "/assets/img/guitar_Performance.jpeg",
      description: "훈어쿠스틱기타하우스에 오시면 누구든 즐겁게 연주 할 수 있습니다!",
      url: "https://m.blog.naver.com/PostView.naver?blogId=hun_guitar&logNo=223447890996&targetKeyword=&targetRecommendationCode=1&fromRecommendationType=KOREA_MAP&targetRecommendationDetailCode=1",
    },
    {
      id: "9",
      title: "통기타 종류, 통기타 바디 모양!",
      subtitle: "통기타 종류, 모양에 대해 알고 싶습니다!",
      image: "/assets/img/guitar_types.jpg",
      description: "훈어쿠스틱기타하우스와 함께 배워요!",
      url: "https://m.blog.naver.com/PostView.naver?blogId=hun_guitar&logNo=222947995991&targetKeyword=&targetRecommendationCode=1&fromRecommendationType=KOREA_MAP&targetRecommendationDetailCode=1",
    },
  ];

  return (
    <section className="page-section" id="portfolio">
      <Container>
        <div className="text-center">
          <h2 className="section-heading text-uppercase mb-5">Contents</h2>
        </div>
        <Row>
          {portfolioItems.map((item, index) => (
            <Col key={index} lg={4} sm={6} className="mb-4">
              <div className="portfolio-item">
                <PortfolioModal {...item} />
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading mt-2">{item.title}</div>
                  <div className="portfolio-caption-subheading text-muted" style={{ fontSize: "14px" }}>
                    {item.subtitle}
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default PortfolioSection;
